import { useState } from 'react';
import { useRouter } from 'next/navigation';
import {
  useBookmarkArticleMutation,
  useGetUserQuery,
} from '@/RTK/services/userApi';
import { Bookmark, ImageIcon, MessageCircleMore, Share2 } from 'lucide-react';
import { toast } from 'react-hot-toast';
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlinePlaySquare,
} from 'react-icons/ai';
import { FiBook } from 'react-icons/fi';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { HiMiniBookmark } from 'react-icons/hi2';
import { IoMdLink } from 'react-icons/io';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { MdBookmarkBorder } from 'react-icons/md';
import { useSelector } from 'react-redux';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

import Loader from '../../../../../utils/Loader';
import ShareModal from '../../view-article/components/ShareModal';

export default function ArticleActionDropDownList({ article }) {
  const [open, setOpen] = useState(false);
  const [shareModal, setShareModal] = useState(false);

  const router = useRouter();
  const { token } = useSelector((state) => state.auth);
  const { data } = useGetUserQuery(undefined, {
    skip: !token,
  });

  const [
    bookmarkArticle,
    { isSuccess, isError, error, isLoading: bookmarkLoading },
  ] = useBookmarkArticleMutation();
  const bookmark = data?.user?.bookmarks?.includes(article?._id);

  const handleBookmark = async () => {
    if (!token) {
      toast.error('يجب عليك تسجيل الدخول اولاً');
      setOpen(false);
      return;
    }
    await bookmarkArticle(article?._id);

    toast.success(
      `مقال ${bookmark ? 'غير مُعلَّم بالمفضلة' : 'مُعلَّم بالمفضلة'}`
    );

    setOpen(false);
  };

  const handleCopy = () => {
    const url = window.location.origin + `/view-article/${article?._id}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.success('تم نسخ الرابط');
        setOpen(false);
      })
      .catch((error) => {
        console.error('Error copying URL:', error);
      });
  };

  return (
    <>
      <DropdownMenu
        dir="rtl"
        modal={false}
        onClick={(e) => e.stopPropagation()}
        open={open}
        onOpenChange={setOpen}
      >
        <DropdownMenuTrigger
          asChild
          onClick={(e) => e.stopPropagation()}
          className="cursor-pointer"
        >
          <HiOutlineDotsHorizontal
            size={20}
            color="white"
            onClick={(event) => {
              event.stopPropagation();
            }}
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="w-48 rounded-md bg-[#FAFAFA] p-2 shadow-lg border border-[#E4E4E7]"
          align="end"
        >
          <DropdownMenuItem
            className="flex items-center gap-2 rounded-md p-2 text-[#2E2E2E] hover:bg-gray-100"
            onClick={(e) => {
              handleAction('rename');
              e.stopPropagation();
            }}
          >
            <div
              onClick={(event) => {
                event.stopPropagation(); // Prevent DropdownMenu from closing when Dialog is triggered
              }}
              className="w-full"
            >
              <button
                onClick={() => router.push(`/@${article?.author?.username}`)}
                className="flex w-full shrink-0 justify-between rounded-[6px] text-[#505050] items-center"
              >
                صفحة الكاتب <FiBook size={16} color="#636363" />
              </button>
            </div>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="flex items-center gap-2 rounded-md p-2 text-[#484851] hover:bg-gray-100"
            onClick={(e) => {
              handleAction('rename');
              e.stopPropagation();
            }}
          >
            <div
              onClick={(event) => {
                event.stopPropagation(); // Prevent DropdownMenu from closing when Dialog is triggered
              }}
              className="w-full"
            >
              <button
                onClick={handleBookmark}
                className="flex items-center w-full shrink-0 justify-between rounded-[6px] text-[#484851] items-center"
              >
                {bookmark ? 'إزالة من العلامات' : 'إضافة إلى العلامات '}{' '}
                <div className="flex gap-2">
                  {bookmark ? (
                    <HiMiniBookmark className="text-purple-600" size={16} />
                  ) : (
                    <Bookmark size={16} />
                  )}
                  {bookmarkLoading ? <Loader size="sm" /> : ''}
                </div>
              </button>
            </div>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="flex items-center gap-2 rounded-md p-2 text-[#2E2E2E] hover:bg-gray-100"
            onClick={(e) => {
              handleAction('rename');
              e.stopPropagation();
            }}
          >
            <div
              onClick={(event) => {
                handleCopy();
                event.stopPropagation(); // Prevent DropdownMenu from closing when Dialog is triggered
              }}
              className="w-full"
            >
              <button className="flex w-full shrink-0 justify-between rounded-[6px] text-[#484851] items-center">
                نسخ الرابط <IoMdLink size={16} color="#636363" />
              </button>
            </div>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="flex items-center gap-2 rounded-md p-2 text-[#2E2E2E] hover:bg-gray-100"
            onClick={(e) => {
              handleAction('rename');
              e.stopPropagation();
            }}
          >
            <div
              onClick={(event) => {
                setShareModal(true);
                event.stopPropagation(); // Prevent DropdownMenu from closing when Dialog is triggered
              }}
              className="w-full"
            >
              <button className="flex w-full shrink-0 justify-between rounded-[6px] text-[#484851] items-center">
                مشاركة <Share2 size={16} color="#636363" />
              </button>
            </div>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {shareModal && (
        <ShareModal
          article={article}
          isOpen={shareModal}
          onClose={() => setShareModal(false)}
        />
      )}
    </>
  );
}
